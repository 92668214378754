import React, { useState } from 'react';
import { SaleItem } from '../../Home/types';
import { formatCurrency } from '../../../utils/formatCurrency';
import { getPaginationNumbers } from '../../../utils/getPaginationNumbers';
import { Checkbox, Flex } from 'theme-ui';
import { formatUTCDate } from '../../../utils/formatDate';

const SalesTable = ({
  salesData,
  title,
}: {
  salesData: any;
  title?: string;
}) => {
  const ITEMS_PER_PAGE = 15;

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;

  const showFees = window.location.hostname.includes('aitech'); //Only showing fees for AI Tech
  const feeSplit = 30;

  const [filter, setFilter] = useState<boolean>(true);
  const filterLowValueBonds = (bonds: any) => {
    return bonds?.filter((bond: any) => bond.dollarValue > 10);
  };

  const bondsToRender = filter
    ? filterLowValueBonds(salesData.Sales)
    : salesData.Sales;
  const totalPages = Math.ceil(bondsToRender.length / ITEMS_PER_PAGE);
  const paginatedSales = bondsToRender.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  const handlePageChange = (
    newPage: number,
    setter: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    setter(newPage);
  };

  return (
    <div className="row align-items-start">
      <div className="row">
        <div className="col-6">
          <h5 className="mb-3 text-uppercase">{title}</h5>
        </div>
        <div className="col-6">
          <Flex sx={{ float: 'right' }} onClick={() => setFilter(!filter)}>
            <Checkbox sx={{ mr: '5px' }} checked={filter} />
            Filter low value bonds
          </Flex>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>Bond</th>
              <th>Bond Address</th>
              <th>Buyer</th>
              <th>Amount Paid</th>
              <th>Token Amount</th>
              <th>Payout Token Price</th>
              <th>Payout Value</th>
              <th>Discount</th>
              <th>Sale Date</th>
              {showFees === true && <th>Estimated Fees</th>}
            </tr>
          </thead>
          <tbody>
            {paginatedSales.map((item: SaleItem, index: number) => (
              <tr key={index}>
                <td width="30px">
                  <img src={`/${item.chainId}.png`} width="20px" />
                </td>
                <td>
                  <a href={`/bond/${item.contractAddress}`}>{item.lp}</a>
                </td>
                <td>
                  {item.contractAddress.substring(0, 5)}...
                  {item.contractAddress.substring(
                    item.contractAddress.length - 5,
                  )}
                </td>
                <td>
                  <a href={`/address/${item.createdAddressOwner}`}>
                    {item.createdAddressOwner.substring(0, 5)}...
                    {item.createdAddressOwner.substring(
                      item.createdAddressOwner.length - 5,
                    )}
                  </a>
                </td>
                <td className="highlighted-column">
                  {formatCurrency(item.dollarValue)}
                </td>
                <td>
                  {item.payout.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>

                <td>
                  {formatCurrency(
                    item.payoutTokenPrice,
                    true,
                    item.payoutTokenPrice > 1 ? 2 : 5,
                  )}
                </td>

                <td>{formatCurrency(item.payout * item.payoutTokenPrice)}</td>
                <td>
                  {(
                    ((item.payoutTokenPrice - item.dollarValue / item.payout) /
                      item.payoutTokenPrice) *
                    100
                  ).toFixed(2)}
                  %
                </td>
                <td>{formatUTCDate(new Date(item.createdAt * 1000))}</td>
                {showFees === true && (
                  <td>
                    {formatCurrency(
                      (((item.dollarValue / 100) * 5) / 100) * feeSplit,
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Sales Pagination */}
      <div className="d-flex justify-content-end">
        <nav aria-label="Sales Page navigation">
          <ul className="pagination">
            {getPaginationNumbers(totalPages, currentPage).map(page => (
              <li
                className={`page-item ${currentPage === page ? 'active' : ''}`}
                key={page}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(page, setCurrentPage)}
                >
                  {page}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default SalesTable;
