export function formatUTCDate(date: Date, short: boolean = false) {
  const pad = (num: number) => num.toString().padStart(2, '0');

  // Array of month abbreviations
  const monthAbbreviations = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const year = date.getUTCFullYear();
  const month = monthAbbreviations[date.getUTCMonth()]; // Use the month array for abbreviation
  const day = pad(date.getUTCDate());
  const hours = pad(date.getUTCHours());
  const minutes = pad(date.getUTCMinutes());

  return short === true
    ? `${day}-${month}-${year.toString().substr(-2)}`
    : `${day} ${month} ${year} ${hours}:${minutes}`;
}
