export const getPaginationNumbers = (totalPages: number, currPage: number) => {
  let start = Math.max(2, currPage - 2);
  let end = Math.min(totalPages - 1, currPage + 2);

  if (currPage <= 3) {
    start = 1;
    end = Math.min(5, totalPages);
  } else if (totalPages - currPage < 3) {
    start = Math.max(1, totalPages - 4);
    end = totalPages;
  }

  let pages = [1];
  for (let i = start; i <= end; i++) {
    if (i !== 1 && i !== totalPages) {
      pages.push(i);
    }
  }
  if (totalPages > 1) {
    pages.push(totalPages);
  }

  return pages;
};
