import React from 'react';
import { Box, Select } from 'theme-ui';

const Dropdown = ({
  options,
  selectedOption,
  onChange,
}: {
  options: string[];
  selectedOption: string;
  onChange: (event: any) => void;
}) => {
  return (
    <Box sx={{ width: '120px' }}>
      <Select onChange={onChange} value={selectedOption} sx={{ width: '100%' }}>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default Dropdown;
