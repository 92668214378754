import React, { FC, useEffect, useState } from 'react';
import styles from './Address.module.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { BOND_DASHBOARD_API_URL } from '../../config/constants';
import { formatUTCDate } from '../../utils/formatDate';
import { Checkbox, Flex } from 'theme-ui';
import { formatCurrency } from '../../utils/formatCurrency';
import { Knob } from 'primereact/knob';
import { getPaginationNumbers } from '../../utils/getPaginationNumbers';

interface AddressProps {}

interface SaleItem {
  contractAddress: string;
  billNftId: string;
  payoutTokenPrice: number;
  createdAddressOwner: string;
  createdAt: number;
  lp: string;
  payoutToken: string;
  vestingTime: number;
  payout: number;
  deposit: number;
  dollarValue: number;
  chainId: string;
}

const options = {
  scales: {
    y: {
      grid: {
        display: true,
        color: 'rgba(250, 250, 250, 0.1)',
        drawOnChartArea: true,
      },
      beginAtZero: true,
    },
    x: {
      grid: {
        display: true,
        color: 'rgba(250, 250, 250, 0.1)',
        drawOnChartArea: true,
      },
      ticks: {
        maxTicksLimit: 10,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  pointRadius: 0,
  responsive: true,
  maintainAspectRatio: false,
};

interface ChartDataset {
  label: string;
  data: number[];
  fill: boolean;
  borderColor: string;
  backgroundColor: string;
  tension: number;
}

// Define an interface for the chart data
interface ChartData {
  labels: string[];
  datasets: ChartDataset[];
}

function Address() {
  const { address } = useParams();
  const showFees = window.location.hostname.includes('aitech'); //Only showing fees for AI Tech
  const feeSplit = 30;

  function stripDate(input: number): string {
    const date = new Date(input * 1000);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
    const day = date.getUTCDate();

    // console.log(date);
    // console.log(`${year}-${month}-${day}`);
    // console.log('---------');
    return `${year}-${month}-${day}`;
  }

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const [salesCountChartData, setSalesCountChartData] = useState<ChartData>({
    labels: [],
    datasets: [
      {
        label: 'Sales Count',
        data: [],
        fill: true,
        borderColor: '#6560C5',
        backgroundColor: 'rgba(101, 96, 197, 0.9)',
        tension: 0.4,
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${BOND_DASHBOARD_API_URL}/collector/userSalesInfo/purchased/${address}`,
      )
      .then(response => {
        setData(response.data);
        const reversedData = [...response.data.Sales].reverse();
        const countsByDate: { salesNum: number; salesAmount: number }[] =
          reversedData.reduce((acc, item) => {
            const dateStr = stripDate(item.createdAt);

            if (!acc[dateStr]) {
              acc[dateStr] = { salesNum: 0, salesAmount: 0 };
            }

            const dollarValue = parseFloat(item.dollarValue) || 0;
            acc[dateStr].salesNum += 1;
            acc[dateStr].salesAmount += Math.round(dollarValue);

            return acc;
          }, {});

        const salesByDate = Object.values(countsByDate);
        const labels = Object.keys(countsByDate);

        setSalesCountChartData({
          labels: labels,
          datasets: [
            {
              ...salesCountChartData.datasets[0],
              data: salesByDate.map(sale => sale?.salesNum),
            },
          ],
        });
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const filteredSales = data?.Sales || [];
  const totalPages = Math.ceil(filteredSales.length / ITEMS_PER_PAGE);

  const handlePageChange = (
    newPage: number,
    setter: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    setter(newPage);
  };

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const paginatedSales = filteredSales.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE,
  );

  return (
    <>
      <div className="row mb-3 mt-2">
        <div className="col-md-7 col-sm-12">
          <div className="detail-box">
            <h5 className="mb-3 text-uppercase">Daily Sales</h5>
            <div className={styles.chartContainer}>
              <Line options={options} data={salesCountChartData} />
            </div>
          </div>
        </div>
        <div className="col-md-5 col-sm-12">
          <div className="row">
            <div className="col-12">
              <div className="detail-box">
                <div className="row mb-3">
                  <div className="col-4">
                    <h4 className="mb-0">{data?.Sales?.length}</h4>
                    <span className="text-uppercase mb-0">Purchases</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {formatCurrency(data?.totalDollarValue)}
                    </h4>
                    <span className="text-uppercase mb-0">Total $ Value</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {formatCurrency(
                        data?.totalDollarValue / data?.Sales?.length,
                      )}
                    </h4>
                    <span className="text-uppercase mb-0">AVG Sale Value</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h4 className="mb-0">
                      {data?.Sales?.length > 0 &&
                        formatUTCDate(
                          new Date(
                            data.Sales[data.Sales.length - 1].createdAt * 1000,
                          ),
                          true,
                        )}
                    </h4>
                    <span className="text-uppercase mb-0">First Sale</span>
                  </div>
                  <div className="col-4">
                    <h4 className="mb-0">
                      {data?.Sales?.length > 0 &&
                        formatUTCDate(
                          new Date(data.Sales[0].createdAt * 1000),
                          true,
                        )}
                    </h4>
                    <span className="text-uppercase mb-0">
                      Most Recent Sale
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="col-6">*/}
        {/*  <h5 className="mt-3 mb-3">Daily Sales Value (USD)</h5>*/}
        {/*  <div className={styles.chartContainer}>*/}
        {/*    <Line options={options} data={salesAmountChartData} />*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <div className="detail-box">
        <div className="row">
          <div className="col-6">
            <h5 className="mb-3 text-uppercase">All Purchases</h5>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Bond</th>
                <th>Bond Address</th>
                <th>Amount Paid</th>
                <th>Token Amount</th>

                <th>Payout Token Price</th>
                <th>Payout Value</th>
                <th>Discount</th>
                <th>Sale Date</th>
                {showFees === true && <th>Estimated Fees</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedSales.map((item: SaleItem, index: number) => (
                <tr key={index}>
                  <td width="30px">
                    <img src={`/${item.chainId}.png`} width="20px" />
                  </td>
                  <td>
                    <a href={`/bond/${item.contractAddress}`}>{item.lp}</a>
                  </td>
                  <td>
                    {item.contractAddress.substring(0, 5)}...
                    {item.contractAddress.substring(
                      item.contractAddress.length - 5,
                    )}
                  </td>
                  <td className="highlighted-column">
                    {formatCurrency(item.dollarValue)}
                  </td>
                  <td>
                    {item.payout.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>

                  <td>
                    {formatCurrency(
                      item.payoutTokenPrice,
                      true,
                      item.payoutTokenPrice > 1 ? 2 : 5,
                    )}
                  </td>

                  <td>{formatCurrency(item.payout * item.payoutTokenPrice)}</td>
                  <td>
                    {(
                      ((item.payoutTokenPrice -
                        item.dollarValue / item.payout) /
                        item.payoutTokenPrice) *
                      100
                    ).toFixed(2)}
                    %
                  </td>
                  <td>{formatUTCDate(new Date(item.createdAt * 1000))}</td>
                  {showFees === true && (
                    <td>
                      {formatCurrency(
                        (((item.dollarValue / 100) * 5) / 100) * feeSplit,
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-end mb-0">
          <nav aria-label="Bonds Page navigation">
            <ul className="pagination">
              {getPaginationNumbers(totalPages, currentPage).map(page => (
                <li
                  className={`page-item ${
                    currentPage === page ? 'active' : ''
                  }`}
                  key={page}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(page, setCurrentPage)}
                  >
                    {page}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Address;
