export function formatCurrency(
  value: number,
  decimals: boolean = true,
  decimalCount: number = 2,
) {
  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals === true ? decimalCount : 0,
    maximumFractionDigits: decimals === true ? decimalCount : 0,
  }).format(value);

  let formattedFinal = formattedCurrency.toString();
  if (value >= 1000000 && decimals === false) {
    formattedFinal = '$' + (value / 1000000).toFixed(2) + 'm';
  } else if (value >= 1000 && decimals === false) {
    formattedFinal = '$' + (value / 1000).toFixed(1) + 'k';
  }

  return formattedFinal;
}
