import React, { useState } from 'react';
import { Flex, Input } from 'theme-ui';
import { useNavigate } from 'react-router-dom';
import Dropdown from './Dropdown';

const Searcher = () => {
  const [addressString, setAddressString] = useState<string>('');
  const options = ['Bond', 'Address', 'Project'];
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate(`/${selectedOption}/${addressString}`); // Navigate to the new URL
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Flex sx={{ flexDirection: 'column' }}>
      <Flex>
        <Dropdown
          options={options}
          selectedOption={selectedOption}
          onChange={handleChange}
        />
        <Input
          placeholder={`Get insights by ${
            selectedOption === 'Bond'
              ? 'bond address'
              : selectedOption === 'Address'
              ? 'wallet address'
              : 'raise token'
          }`}
          onChange={(event: any) => setAddressString(event.target.value)}
          onKeyDown={handleKeyPress}
          sx={{ ml: '10px' }}
        />
      </Flex>
    </Flex>
  );
};

export default Searcher;
