import React, { FC, useEffect, useState } from 'react';
import styles from './Period.module.scss';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { BOND_DASHBOARD_API_URL } from '../../config/constants';
import { formatUTCDate } from '../../utils/formatDate';
import { Checkbox, Flex } from 'theme-ui';
import { formatCurrency } from '../../utils/formatCurrency';
import { Knob } from 'primereact/knob';
import { getPaginationNumbers } from '../../utils/getPaginationNumbers';
import SalesTable from './components/SalesTable';

interface AddressProps {}

interface SaleItem {
  contractAddress: string;
  billNftId: string;
  payoutTokenPrice: number;
  createdAddressOwner: string;
  createdAt: number;
  lp: string;
  payoutToken: string;
  vestingTime: number;
  payout: number;
  deposit: number;
  dollarValue: number;
  chainId: string;
}

interface ChainDetail {
  chainId: string;
  total: number;
  averageROE: number;
}

function Period() {
  const { period } = useParams();

  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const domain = window.location.hostname;

    let url = `${BOND_DASHBOARD_API_URL}/collector/salesInfo/period/${period}/0`;

    if (domain.includes('aitech')) {
      url = `${BOND_DASHBOARD_API_URL}/collector/salesInfo/period/${period}/ai-tech`;
    }

    axios
      .get(url)
      .then(response => {
        setData(response.data);

        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <div className="detail-box mb-4">
        <div className="row">
          {data.ChainIdTotals &&
            data.ChainIdTotals.map((chain: ChainDetail) => {
              return (
                <div key={chain.chainId} className="col text-center">
                  <img
                    src={`/${chain.chainId}.png`}
                    className={styles.chainImg}
                  />
                  <h4 className="text-uppercase mb-0 mt-2">
                    {formatCurrency(Number(chain.total))}
                  </h4>
                  <h5 className="mt-1 mb-0">
                    ROE: {Number(chain.averageROE).toFixed(2)}%
                  </h5>
                </div>
              );
            })}
        </div>
      </div>
      <div className="detail-box mb-4">
        <h5 className="mb-0">
          ROE Across All Chains:{' '}
          {data.overallAverageROE && data.overallAverageROE.toFixed(2)}%
        </h5>
      </div>
      <div className="detail-box">
        {data.Sales && (
          <SalesTable salesData={data} title={period?.replace('_', ' ')} />
        )}
      </div>
    </>
  );
}

export default Period;
