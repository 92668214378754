export interface SaleItem {
  vestingTerm: number;
  contractAddress: string;
  billNftId: string;
  payoutTokenPrice: number;
  createdAddressOwner: string;
  createdAt: number;
  lp: string;
  payoutToken: string;
  vestingTime: number;
  payout: number;
  deposit: number;
  dollarValue: number;
  chainId: string;
  totalSales: number;
  totalDollarValue: number;
  isFirstTimeBuyer: boolean;
  percentageSold: number;
  type: string;
  soldOut: boolean;
  lastPurchaseDate: string;
}

export const ITEMS_PER_PAGE = 20;
