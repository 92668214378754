import React, { useEffect, useState } from 'react';
import { ITEMS_PER_PAGE, SaleItem } from '../types';
import { formatCurrency } from '../../../utils/formatCurrency';
import { getPaginationNumbers } from '../../../utils/getPaginationNumbers';
import { Input } from 'theme-ui';

//TODO: why do we use salesData here??
const BondsTable = ({ salesData, bondData }: any) => {
  const [bondsCurrentPage, setBondsCurrentPage] = useState(1);
  const [paginatedBonds, setPaginatedBonds] = useState([]);
  const [bondsTotalPages, setBondsTotalPages] = useState(0);
  const [sortedBonds, setSortedBonds] = useState([]);
  const bondsStartIndex = (bondsCurrentPage - 1) * ITEMS_PER_PAGE;
  const [showFlashBonds, setShowFlashBonds] = useState(false);
  const [showLaunchBonds, setShowLaunchBonds] = useState(false);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const updateSort = (field: string) => {
    if (field === sortField && sortDirection === 'desc') {
      //reset sort
      setSortDirection('asc');
      setSortField('');
    } else if (field === sortField) {
      //means already sorted by this, so switch direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      //reset sort direction
      setSortDirection('asc');
      setSortField(field);
    }
  };

  useEffect(() => {
    if (sortField) {
      const sortedData = sortData(sortField, sortDirection);
      // @ts-ignore
      setSortedBonds(sortedData);
    } else {
      setSortedBonds(salesData.bonds);
    }
  }, [sortField, sortDirection, salesData.bonds]);

  const sortData = (field: string, direction: string) => {
    return [...salesData.bonds].sort((a, b) => {
      let aValue = a[field];
      let bValue = b[field];

      if (field === 'discount') {
        aValue = getDiscount(a.contractAddress);
        bValue = getDiscount(b.contractAddress);

        // Always push '-' or '100%' to the bottom
        if (aValue === '-' || aValue === null || aValue === '100%') return 1;
        if (bValue === '-' || bValue === null || bValue === '100%') return -1;

        // Convert to numbers for comparison
        const aValueNum = parseFloat(aValue);
        const bValueNum = parseFloat(bValue);

        if (aValueNum < bValueNum) return direction === 'asc' ? -1 : 1;
        if (aValueNum > bValueNum) return direction === 'asc' ? 1 : -1;
        return 0;
      } else if (field === 'percentageSold') {
        aValue = a.soldOut ? 100 : a.percentageSold;
        bValue = b.soldOut ? 100 : b.percentageSold;
      }
      if (aValue < bValue) return direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      return 0;
    });
  };

  const domain = window.location.hostname;
  const hideFilters =
    domain.includes('polygon-bonds-dashboard') || domain.includes('aitech');

  const toggleShowFlashBonds = () => {
    setShowFlashBonds(showFlashBonds != true);
  };

  const toggleShowLaunchBonds = () => {
    setShowLaunchBonds(showLaunchBonds != true);
  };

  useEffect(() => {
    let bondsToShow = sortedBonds;

    if (!showFlashBonds) {
      bondsToShow = bondsToShow?.filter(
        (x: any) => !'USDT-WBNB,USDT-BTCB,USDT-ETH'.includes(x.lp),
      );
    }

    if (!showLaunchBonds) {
      bondsToShow = bondsToShow?.filter(
        (x: any) =>
          x.type ||
          x.chainId === 59144 ||
          (!x.type && 'USDT-WBNB,USDT-BTCB,USDT-ETH'.includes(x.lp)),
      );
    }

    setBondsTotalPages(Math.ceil((bondsToShow?.length || 0) / ITEMS_PER_PAGE));

    setPaginatedBonds(
      bondsToShow
        ? bondsToShow.slice(bondsStartIndex, bondsStartIndex + ITEMS_PER_PAGE)
        : [],
    );
  }, [sortedBonds, bondsStartIndex, showFlashBonds, showLaunchBonds]);

  const filterBonds = (input: any) => {
    const filteredBonds = salesData?.bonds.filter((x: any) =>
      x.lp.toLowerCase().includes(input.toLowerCase()),
    );

    setBondsTotalPages(Math.ceil((filteredBonds.length || 0) / ITEMS_PER_PAGE));

    setPaginatedBonds(
      filteredBonds
        ? filteredBonds
            .filter((x: any) =>
              x.lp.toLowerCase().includes(input.toLowerCase()),
            )
            .slice(bondsStartIndex, bondsStartIndex + ITEMS_PER_PAGE)
        : [],
    );
  };

  const handlePageChange = (
    newPage: number,
    setter: React.Dispatch<React.SetStateAction<number>>,
  ) => {
    setter(newPage);
  };

  const getDiscount = (bondAddress: string) => {
    if (bondData.length === 0) {
      return '-';
    }

    try {
      const bill = bondData?.find(
        (x: any) => x.billAddress.toLowerCase() === bondAddress.toLowerCase(),
      );

      return bill ? `${bill.discount.toFixed(2)}%` : '-';
    } catch (ex) {
      return '-';
    }
  };

  return (
    <div className="row align-items-start">
      <div className="col-12">
        <div className="detail-box">
          <div className="row">
            <div className="col-md-4">
              <h5 className="mb-3 mt-1 text-uppercase">All Bonds</h5>
            </div>
            {!hideFilters && (
              <div className="col-md-5 text-md-end mt-negative-5 mb-2">
                <button
                  type="button"
                  className={`btn btn-sm btn-primary me-3 ${
                    showLaunchBonds === false ? 'btn-semi-transparent' : ''
                  }`}
                  onClick={() => toggleShowLaunchBonds()}
                >
                  Show Launch Bonds
                </button>
                <button
                  type="button"
                  className={`btn btn-sm btn-primary ${
                    showFlashBonds === false ? 'btn-semi-transparent' : ''
                  }`}
                  onClick={() => toggleShowFlashBonds()}
                >
                  Show Flash Bonds
                </button>
              </div>
            )}

            <div className="col-md-3 mt-negative-10">
              <Input
                placeholder="Filter Bonds"
                onChange={(event: any) => filterBonds(event.target.value)}
                sx={{ mb: '15px' }}
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th onClick={() => updateSort('lp')} className="sortable">
                    Bond
                    <i
                      className={`fa-solid ${
                        sortField === 'lp'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                  <th onClick={() => updateSort('type')} className="sortable">
                    Type
                    <i
                      className={`fa-solid ${
                        sortField === 'type'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                  <th>Contract Address</th>
                  <th
                    onClick={() => updateSort('totalSales')}
                    className="sortable"
                  >
                    Sales
                    <i
                      className={`fa-solid ${
                        sortField === 'totalSales'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                  <th
                    onClick={() => updateSort('discount')}
                    className="sortable"
                  >
                    Discount
                    <i
                      className={`fa-solid ${
                        sortField === 'discount'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                  <th
                    onClick={() => updateSort('totalDollarValue')}
                    className="sortable"
                  >
                    Total $ Value
                    <i
                      className={`fa-solid ${
                        sortField === 'totalDollarValue'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                  <th
                    onClick={() => updateSort('vestingTerm')}
                    className="sortable"
                  >
                    Vesting
                    <i
                      className={`fa-solid ${
                        sortField === 'vestingTerm'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                  {/*<th>First Sale</th>*/}
                  {/*<th>Last Sale</th>*/}
                  <th
                    onClick={() => updateSort('percentageSold')}
                    className="sortable"
                  >
                    % Sold
                    <i
                      className={`fa-solid ${
                        sortField === 'percentageSold'
                          ? sortDirection === 'asc'
                            ? 'fa-sort-down'
                            : 'fa-sort-up'
                          : 'fa-sort'
                      }`}
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {paginatedBonds.map((item: SaleItem, index: number) => (
                  <tr key={index}>
                    <td width="30px">
                      <img
                        src={`/${item.chainId}.png`}
                        width="20px"
                        alt={item.chainId}
                      />
                    </td>

                    <td>
                      <a href={`/bond/${item.contractAddress}`}>{item.lp}</a>
                    </td>
                    <td>
                      {item.type
                        ? item.type?.toUpperCase()
                        : 'USDT-WBNB,USDT-BTCB,USDT-ETH'.includes(
                            item.lp.toUpperCase(),
                          )
                        ? 'FLASH'
                        : item.chainId == '59144'
                        ? '-'
                        : 'LAUNCH'}
                    </td>
                    <td>
                      {item.contractAddress.substring(0, 5)}...
                      {item.contractAddress.substring(
                        item.contractAddress.length - 5,
                      )}
                    </td>
                    <td>{item.totalSales}</td>
                    <td width="170px">
                      {item.soldOut === true
                        ? '-'
                        : getDiscount(item.contractAddress)}
                    </td>
                    <td>{formatCurrency(item.totalDollarValue)}</td>
                    <td>
                      {!isNaN(item.vestingTerm / 86400)
                        ? `${item.vestingTerm / 86400}d`
                        : '-'}
                    </td>
                    {/*<td>TBA</td>*/}
                    {/*<td>TBA</td>*/}
                    <td width="120px">
                      {item.percentageSold ? (
                        <div className="progress" style={{ marginTop: '3px' }}>
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                              width: `${
                                item.soldOut === true
                                  ? 100
                                  : item.percentageSold
                              }%`,
                              backgroundColor:
                                item.soldOut === true ||
                                item.percentageSold > 80
                                  ? '#FFC007'
                                  : '#28A745',
                            }}
                            aria-valuenow={
                              item.soldOut === true
                                ? 100
                                : Math.round(item.percentageSold)
                            }
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                          <span className="progress-text">
                            {item.soldOut === true
                              ? 100
                              : Math.round(item.percentageSold)}
                            %
                          </span>
                        </div>
                      ) : (
                        '-'
                      )}
                    </td>
                    {/*<td>{item.lastPurchaseDate}</td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Bonds Pagination */}

          <div className="d-flex justify-content-end mb-0">
            <nav aria-label="Bonds Page navigation">
              <ul className="pagination">
                {getPaginationNumbers(bondsTotalPages, bondsCurrentPage).map(
                  page => (
                    <li
                      className={`page-item ${
                        bondsCurrentPage === page ? 'active' : ''
                      }`}
                      key={page}
                    >
                      <button
                        className="page-link"
                        onClick={() =>
                          handlePageChange(page, setBondsCurrentPage)
                        }
                      >
                        {page}
                      </button>
                    </li>
                  ),
                )}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BondsTable;
