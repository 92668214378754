import React, { useEffect, useState } from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from '../../Bond/Bond.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const HomeChart = ({
  chartData,
  title,
  type,
  labels,
}: {
  chartData: any;
  title: string;
  type: string;
  labels?: any;
}) => {
  interface LineChartData extends ChartData<'line'> {}

  const [internalChartData, setInternalChartData] = useState<LineChartData>({
    labels: type === 'months' ? getLast6Months() : labels,
    datasets: [
      {
        label: 'Sales Count',
        data: [],
        fill: true,
        borderColor: '#6560C5',
        backgroundColor: 'rgba(101, 96, 197, 0.9)',
        tension: 0.4,
      },
    ],
  });

  const options = {
    scales: {
      y: {
        grid: {
          display: true,
          color: 'rgba(250, 250, 250, 0.1)',
          drawOnChartArea: true,
        },
        beginAtZero: true,
        ticks: {
          stepSize: type === 'months' ? 300000 : 25000,
        },
      },
      x: {
        grid: {
          display: true,
          color: 'rgba(250, 250, 250, 0.1)',
          drawOnChartArea: true,
        },
        ticks: {
          maxTicksLimit: 10,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    pointRadius: 0,
    responsive: true,
    maintainAspectRatio: false,
  };

  useEffect(() => {
    if (chartData && chartData.length > 0) {
      setInternalChartData(prevChartData => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: chartData,
          },
        ],
      }));
    }
  }, [chartData, type]);

  return (
    <div className="detail-box mb-3">
      <div className={styles.chartContainer}>
        <h5 className="mb-3 text-uppercase">{title}</h5>
        <div className={styles.chartInnerContainer}>
          <Line options={options} data={internalChartData} />
        </div>
      </div>
    </div>
  );
};

export default HomeChart;

function getLast6Months() {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const now = new Date();
  let months = [];

  for (let i = 6; i > 0; i--) {
    let month = new Date(now.getFullYear(), now.getMonth() - i, 1);
    months.push(monthNames[month.getMonth()]);
  }

  return months;
}
